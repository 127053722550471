"use client"

import React, { useState, useMemo } from "react"
import Link from "next/link"
import { Menu } from "lucide-react"
import { Button } from "@/components/ui/button"
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import ThemeImage from "@/components/ThemeImage"
import {
  Calculator,
  FileText,
  Database,
  BellRing,
  Mail,
  FileQuestion,
  Shield,
  Newspaper,
  Scale,
  Archive,
  TableOfContents,
  Book,
  Link as LinkIcon,
  NotebookPen,
  Handshake,
  BadgeJapaneseYen,
} from "lucide-react"

// アイコンのマッピング
const iconMapping: Record<string, React.ElementType> = {
  Calculator,
  FileText,
  Database,
  BellRing,
  Mail,
  FileQuestion,
  Shield,
  Newspaper,
  Scale,
  Archive,
  TableOfContents,
  Book,
  Link: LinkIcon,
  NotebookPen,
  Handshake,
  BadgeJapaneseYen,
}

interface RouteItem {
  href?: string
  label: string
  description?: string
  icon?: string
  items?: {
    href: string
    label: string
    description?: string
    icon?: string
  }[]
}

interface NavbarMobileProps {
  routeList: RouteItem[]
}

const NavbarMobileComponent: React.FC<NavbarMobileProps> = ({ routeList }) => {
  const [isOpen, setIsOpen] = useState(false)

  // routeList を useMemo でメモ化
  const memoizedRouteList = useMemo(() => routeList, [routeList])

  return (
    <div className="flex lg:hidden justify-center">
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
          <Button
            variant="ghost"
            className="border-primary/80 hover:ring-2 hover:ring-primary/80 transition duration-500"
          >
            <Menu className="h-5 w-5 mr-2" />
            Menu
          </Button>
        </SheetTrigger>
        <SheetContent side="top" className="w-full h-[80vh]">
          <SheetHeader>
            <SheetTitle>
              <Link href="/" className="z-10">
                <ThemeImage className="" />
              </Link>
            </SheetTitle>
          </SheetHeader>
          <Accordion
            type="single"
            collapsible
            className="w-full overflow-y-auto max-h-[calc(80vh-4rem)]"
          >
            {memoizedRouteList.map((route, i) => (
              <AccordionItem value={`item-${i}`} key={i} className="border-b ">
                {route.items ? (
                  <>
                    <AccordionTrigger className="py-4 px-6text-base font-normal hover:no-underline hover:bg-accent flex items-start text-left justify-start">
                      {/* アイコンを表示 */}
                      {route.icon && (
                        <span className="mr-2 mt-1 ">
                          {React.createElement(iconMapping[route.icon], {
                            className: "h-5 w-5",
                          })}
                        </span>
                      )}
                      <div>
                        <div className="font-bold">{route.label}</div>
                        {route.description && (
                          <p className="text-sm text-muted-foreground mt-1">
                            {route.description}
                          </p>
                        )}
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="flex flex-col pl-1">
                        {route.items.map((item) => {
                          const Icon = item.icon ? iconMapping[item.icon] : null
                          return (
                            <Link
                              key={item.href}
                              href={item.href}
                              onClick={() => setIsOpen(false)}
                              className="flex items-start py-4 px-6 text-base hover:bg-accent"
                            >
                              {Icon && <Icon className="h-5 w-5 mr-2 mt-1" />}
                              <div>
                                <div className="font-medium">{item.label}</div>
                                {item.description && (
                                  <p className="text-sm text-muted-foreground mt-1">
                                    {item.description}
                                  </p>
                                )}
                              </div>
                            </Link>
                          )
                        })}
                      </div>
                    </AccordionContent>
                  </>
                ) : (
                  <Link
                    href={route.href!}
                    onClick={() => setIsOpen(false)}
                    className="flex items-start py-4 px-6 text-base hover:bg-accent"
                  >
                    {/* アイコンを表示 */}
                    {route.icon && (
                      <span className="mr-2 mt-1">
                        {React.createElement(iconMapping[route.icon], {
                          className: "h-5 w-5",
                        })}
                      </span>
                    )}
                    <div>
                      <div className="font-medium">{route.label}</div>
                      {route.description && (
                        <p className="text-sm text-muted-foreground mt-1">
                          {route.description}
                        </p>
                      )}
                    </div>
                  </Link>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </SheetContent>
      </Sheet>
    </div>
  )
}

const NavbarMobile = React.memo(NavbarMobileComponent)

export default NavbarMobile
