import Image from "next/image"

interface ThemeImageProps {
  className?: string
}

const ThemeImage = ({ className }: ThemeImageProps) => {
  const lightSrc = "/logo_lightmode.png"
  const darkSrc = "/logo_darkmode.png"
  const alt = "Logo"

  return (
    <>
      <Image
        src={lightSrc}
        alt={alt}
        width={1920}
        height={1080}
        style={{ height: "75px", width: "auto" }}
        className={`${className} block dark:hidden`}
        priority
      />
      <Image
        src={darkSrc}
        alt={alt}
        width={1920}
        height={1080}
        style={{ height: "75px", width: "auto" }}
        className={`${className} hidden dark:block`}
        priority
      />
    </>
  )
}

export default ThemeImage
