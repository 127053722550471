import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const postPerPage = 3
export const userPostPerPage = 3
export const commentPerPage = 3

export const getURL = () => {
  const url = process.env.NEXT_PUBLIC_APP_URL

  return url ? `${url}` : `https://localhost:${process.env.PORT || 3000}`
}
