"use client"

import React from "react"
import Link from "next/link"
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
import {
  Calculator,
  FileText,
  Database,
  BellRing,
  Mail,
  FileQuestion,
  Shield,
  Newspaper,
  Scale,
  Archive,
  TableOfContents,
  Book,
  Link as LinkIcon,
  NotebookPen,
  Handshake,
  BadgeJapaneseYen,
} from "lucide-react"

// アイコンのマッピング
const iconMapping: Record<string, React.ElementType> = {
  Calculator,
  FileText,
  Database,
  BellRing,
  Mail,
  FileQuestion,
  Shield,
  Newspaper,
  Scale,
  Archive,
  TableOfContents,
  Book,
  Link: LinkIcon,
  NotebookPen,
  Handshake,
  BadgeJapaneseYen,
}

interface RouteItem {
  href?: string
  label: string
  description?: string
  icon?: string // アイコン名を文字列で受け取る
  items?: {
    href: string
    label: string
    description?: string
    icon?: string
  }[]
}

interface NavbarDesktopProps {
  routeList: RouteItem[]
}

const NavbarDesktopComponent: React.FC<NavbarDesktopProps> = ({
  routeList,
}) => {
  return (
    <NavigationMenu className="hidden lg:flex">
      <ul className="flex gap-2 items-center">
        {routeList.map((route, i) => (
          <NavigationMenuItem key={i} className="relative">
            {route.items ? (
              <>
                <NavigationMenuTrigger className={navigationMenuTriggerStyle()}>
                  {route.label}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="w-[420px] p-2">
                    {route.items.map((item) => {
                      const Icon = item.icon ? iconMapping[item.icon] : null // アイコンを取得
                      return (
                        <li key={item.href}>
                          <NavigationMenuLink asChild>
                            <Link
                              href={item.href}
                              className="flex items-center space-x-2 rounded-md p-2 hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                            >
                              {Icon && (
                                <Icon className="h-4 w-4 flex-shrink-0" />
                              )}
                              <div className="pl-2">
                                <div className="text-sm font-bold leading-none">
                                  {item.label}
                                </div>
                                {item.description && (
                                  <p className="line-clamp-1 text-xs leading-snug text-muted-foreground mt-1">
                                    {item.description}
                                  </p>
                                )}
                              </div>
                            </Link>
                          </NavigationMenuLink>
                        </li>
                      )
                    })}
                  </ul>
                </NavigationMenuContent>
              </>
            ) : (
              <Link href={route.href!} legacyBehavior passHref>
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                  {route.label}
                </NavigationMenuLink>
              </Link>
            )}
          </NavigationMenuItem>
        ))}
      </ul>
    </NavigationMenu>
  )
}

const NavbarDesktop = React.memo(NavbarDesktopComponent)

export default NavbarDesktop
